<script setup lang="ts">
import type { PropType } from 'vue';

import {
    type AuctionAssetBusEntity,
    type AuctionAssetBusListingItem,
    type AuctionItemEntity,
    type AuctionListingItemEntity,
    AuctionType,
} from '~/apiClient';

type BadgeConfig = { bg: string; text: string; icon: string; label: string };

// PROPS
const props = defineProps({
    auction: { type: Object as PropType<AuctionItemEntity | AuctionListingItemEntity>, required: true },
    iconOnly: { type: Boolean, default: false },
});

// COMPOSABLES
const { tt } = useTypedI18n();

// DATA
const mainAsset = computed<AuctionAssetBusEntity | AuctionAssetBusListingItem | undefined>(() => props.auction.mainAsset);
const hasScrapedBadge = computed<boolean>(() =>
    (<AuctionType[]>[AuctionType.MonoToScrap, AuctionType.BundleMonoVersionToScrap, AuctionType.BundleMultiVersionToScrap]).includes(
        props.auction.type
    )
);
const hasRevisionBadge = computed<boolean>(() => !!mainAsset.value?.showBadgeInspectionExpired);
const alertBadge = computed<BadgeConfig | undefined>(() => {
    if (hasScrapedBadge.value)
        return {
            bg: 'bg-red-600',
            text: 'text-white',
            icon: 'i-mdi-recycle',
            label: tt('asset.toBeScrapped'),
        };

    if (hasRevisionBadge.value)
        return {
            bg: 'bg-yellow-400',
            text: 'text-black',
            icon: 'i-material-symbols-settings-alert',
            label: tt('asset.expiredInspection'),
        };
});
const hasBundleBadge = computed<boolean>(() =>
    (<AuctionType[]>[
        AuctionType.BundleMonoVersion,
        AuctionType.BundleMultiVersion,
        AuctionType.BundleMultiVersionToScrap,
        AuctionType.BundleMonoVersionToScrap,
    ]).includes(props.auction.type)
);
const badges = computed<BadgeConfig[]>(() => [
    ...insertIf(!!alertBadge.value, { ...alertBadge.value! }),
    ...insertIf(hasBundleBadge.value, {
        bg: 'bg-fl-dark-blue',
        text: 'text-white',
        icon: 'i-mdi-bus-multiple',
        label: tt('asset.bundle'),
    }),
]);
</script>

<template>
    <template v-if="iconOnly">
        <UTooltip
            v-for="(badge, index) in badges"
            :key="index"
            :text="badge.label"
            :popper="{ arrow: true, placement: 'top-start' }"
            :open-delay="200"
        >
            <UBadge variant="solid" size="lg" :ui="{ size: { lg: 'px-2 py-2' } }" :class="[badge.bg, badge.text]" @click.prevent>
                <UIcon class="h-5 w-5" :name="badge.icon" />
            </UBadge>
        </UTooltip>
    </template>
    <template v-else>
        <UBadge
            v-for="(badge, index) in badges"
            :key="index"
            variant="solid"
            size="lg"
            class="flex gap-x-2 overflow-hidden"
            :class="[badge.bg, badge.text]"
        >
            <UIcon class="h-5 w-5" :name="badge.icon" />
            <span class="overflow-hidden text-ellipsis whitespace-nowrap">
                {{ badge.label }}
            </span>
        </UBadge>
    </template>
</template>
